import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import JWTDecode from 'jwt-decode';

import DashboardPage from '../components/DashboardPage.vue';
import CustomerPage from '../components/CustomerPage.vue';
import ManagerPage from '../components/ManagerPage.vue';
import SystemConfigPage from '../components/SystemConfigPage.vue';
import LoginPage from '../components/LoginPage.vue';
import SignupPage from '../components/SignupPage.vue';
import PageNotFound from '../components/PageNotFound.vue';

/**
 * 라우터 관리
 * vue-router 3.5.3 (Vue2) 사용
 * routes에 path와 name, component를 넣어주면 된다.
 */

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'DashboardPage',
    component: DashboardPage,
  },
  {
    path: '/customer',
    name: 'CustomerPage',
    component: CustomerPage,
    meta: { authRequired: true },
  },
  {
    path: '/manager',
    name: 'ManagerPage',
    component: ManagerPage,
    meta: { authRequired: true },
  },
  {
    path: '/systemConfig',
    name: 'SystemConfigPage',
    component: SystemConfigPage,
    meta: { authRequired: true },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { authRequired: false },
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage,
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((routerInfo) => routerInfo.meta.authRequired)) {
    if (!VueCookies.get('accessToken') && !VueCookies.get('refreshToken')) {
      alert('권한이 없습니다. 로그인을 해주세요.');
      next({ name: 'LoginPage' });
    }
  } else if (to.name === 'LoginPage') {
    if (VueCookies.get('accessToken') && VueCookies.get('refreshToken')) {
      next();
    }
  }
  next();

  if (!VueCookies.get('accessToken') && !VueCookies.get('refreshToken')) {
    next({ name: 'LoginPage' });
    return;
  }

  if (JWTDecode(VueCookies.get('accessToken')).roleType === 'ROLE_USER') {
    if (to.name === 'ManagerPage' || to.name === 'SystemConfigPage') {
      alert('화면전용 계정입니다. 권한이 없습니다.');
      next({ name: 'CustomerPage' });
    }
  } else if (
    JWTDecode(VueCookies.get('accessToken')).roleType === 'ROLE_WIZARD' ||
    JWTDecode(VueCookies.get('accessToken')).roleType === 'ROLE_SALES'
  ) {
    if (to.name === 'CustomerPage' || to.name === 'SystemConfigPage') {
      alert('영업사원/위자드 계정입니다. 권한이 없습니다.');
      next({ name: 'ManagerPage' });
    }
  }
});
