<!--
* 회원가입 페이지
* 없어도 무방한 페이지 같지만 일단 남겨둠
-->
<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="2">이름</v-col>
        <v-col class="pb-0" cols="10">
          <v-text-field
            v-model="username"
            placeholder="이름을 입력해주세요"
            outlined
            hide-details
            dense
            full-width
            @keyup.enter="signup"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">아이디</v-col>
        <v-col cols="10" class="pb-0">
          <v-text-field
            v-model="userId"
            placeholder="아이디를 입력해주세요"
            outlined
            hide-details
            dense
            full-width
            @keyup.enter="signup"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">비밀번호</v-col>
        <v-col cols="10" class="pb-0">
          <v-text-field
            v-model="userPw"
            placeholder="비밀번호를 입력해주세요"
            type="password"
            outlined
            hide-details
            dense
            full-width
            @keyup.enter="signup"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-btn @click="signup">회원가입</v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    username: '',
    userId: '',
    userPw: '',
  }),
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------- 회원가입 함수 ---------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async signup() {
      if (this.username === '') {
        alert('이름을 입력해주세요');
      } else if (this.userId === '') {
        alert('아이디를 입력해주세요');
      } else if (this.userPw === '') {
        alert('비밀번호를 입력해주세요');
      } else {
        try {
          const res = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}/api/auth/signup`,
            {
              name: this.username,
              username: this.userId,
              password: this.userPw,
            }
          );
          if (res.status !== 200) {
            return res;
          }
          return res;
        } catch (e) {
          return e.response;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
