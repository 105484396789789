<!--
* 고객 페이지
* 고객이 정보 입력하는 페이지
-->
<template>
  <v-app id="app" class="containerArea">
    <v-container class="containerArea ma-0 pa-0" fluid>
      <v-row class="topArea fill-height ma-0 pa-0" align="center">
        <v-col cols="12" sm="12" md="4" lg="4"></v-col>
        <v-col
          class="logoName mb-1"
          cols="12"
          sm="12"
          md="4"
          lg="4"
          align="center"
        >
          <img
            src="../assets/img/logo_volvo_white.png"
            alt="볼보 로고 이미지"
            class="logoImg"
          />
        </v-col>
        <v-col
          class="locationName pr-9"
          cols="12"
          sm="12"
          md="4"
          lg="4"
          align="end"
        >
          {{ locationName }}
        </v-col>
      </v-row>
      <v-row class="infoArea ma-0 pt-0">
        <v-col cols="12">
          원활한 상담진행을 위해 이름, 연락처, 인원, 방문유형은 필수 입력 정보로
          받고 있습니다. 입장 가능 시 카카오톡으로 안내 드립니다.
        </v-col>
      </v-row>
      <v-row class="mainArea mt-0 mb-3 mx-6 py-3">
        <v-row class="fullWidth ma-0 pa-0 py-6">
          <v-col
            class="inputArea ma-0 px-4 py-0"
            cols="12"
            sm="12"
            md="8"
            lg="8"
          >
            <v-row
              class="ma-0 pa-0"
              :class="$vuetify.breakpoint.md === true ? 'mobile' : ''"
            >
              <v-col class="ma-0 pa-0" cols="12" sm="12" md="12" lg="6">
                <v-row class="ma-0 pa-0" align="center">
                  <v-col
                    class="inputLabel pa-2 pl-5"
                    cols="12"
                    sm="12"
                    md="2"
                    lg="4"
                  >
                    <span class="label">이름</span>
                    <span class="essential">*</span>
                  </v-col>
                  <v-col class="pa-2 ma-0" cols="12" sm="12" md="10" lg="8">
                    <v-text-field
                      v-model="name"
                      placeholder="이름을 입력해주세요."
                      oninput="javascript: this.value = this.value.replace(/[0-9]/g, '');"
                      outlined
                      hide-details
                      full-width
                      elevation-0
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" sm="12" md="12" lg="6">
                <v-row class="ma-0 pa-0" align="center">
                  <v-col
                    class="inputLabel pa-2 pl-5"
                    cols="12"
                    sm="12"
                    md="2"
                    lg="4"
                  >
                    <span class="label">연락처</span>
                    <span class="essential">*</span>
                  </v-col>
                  <v-col class="pa-2 ma-0" cols="12" sm="12" md="10" lg="8">
                    <v-text-field
                      v-model="phoneNumber"
                      placeholder="'-'없이 숫자만 입력해주세요."
                      oninput="javascript: 
                        this.value = this.value.replace(/[^0-9]/g, '');
                        if(this.value.length > 11) {
                          this.value = this.value.substring(0, 11);
                        };"
                      type="tel"
                      outlined
                      hide-details
                      hide-spin-buttons
                      full-width
                      elevation-0
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="input ma-0 pa-0">
              <v-col
                class="inputLabel pa-2 pl-5"
                cols="12"
                sm="12"
                md="2"
                lg="2"
              >
                <span class="label">인원</span>
                <span class="essential">*</span>
              </v-col>
              <v-col class="ma-0 pa-2" cols="12" sm="12" md="10" lg="10">
                <v-btn-toggle
                  class="d-flex overflowScroll"
                  v-model="peopleNumber"
                  background-color="#fff"
                  color="#1c6bba"
                >
                  <v-btn outlined class="flex-grow-1 options">1명</v-btn>
                  <v-btn outlined class="flex-grow-1 options">2명</v-btn>
                  <v-btn outlined class="flex-grow-1 options">3명</v-btn>
                  <v-btn outlined class="flex-grow-1 options">4명</v-btn>
                  <v-btn outlined class="flex-grow-1 options">5명</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class="input ma-0 pa-0">
              <v-col
                class="inputLabel pa-2 pl-5"
                cols="12"
                sm="12"
                md="2"
                lg="2"
              >
                <span class="label">방문유형</span>
                <span class="essential">*</span>
              </v-col>
              <v-col class="ma-0 pa-2" cols="12" sm="12" md="10" lg="10">
                <v-btn-toggle
                  class="d-flex overflowScroll"
                  v-model="visitorType"
                  color="blue darken-2 accent-3"
                >
                  <v-btn
                    value="PREVIEW_HOPE"
                    class="flex-grow-1 options"
                    outlined
                  >
                    관람희망
                  </v-btn>
                  <v-btn
                    value="CONSULT_HOPE"
                    class="flex-grow-1 options"
                    outlined
                  >
                    계약희망
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class="input ma-0 pa-0">
              <v-col
                class="inputLabel label pa-2 pl-5"
                cols="12"
                sm="12"
                md="2"
                lg="2"
              >
                연령대
              </v-col>
              <v-col class="ma-0 pa-2" cols="12" sm="12" md="10" lg="10">
                <v-btn-toggle
                  class="d-flex overflowScroll"
                  v-model="age"
                  color="blue darken-2 accent-3"
                >
                  <v-btn outlined class="flex-grow-1 options">20대</v-btn>
                  <v-btn outlined class="flex-grow-1 options">30대</v-btn>
                  <v-btn outlined class="flex-grow-1 options">40대</v-btn>
                  <v-btn outlined class="flex-grow-1 options">50대</v-btn>
                  <v-btn outlined class="flex-grow-1 options">60대</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class="input ma-0 pa-0">
              <v-col
                class="inputLabel label pa-2 pl-5"
                cols="12"
                sm="12"
                md="2"
                lg="2"
              >
                성별
              </v-col>
              <v-col class="ma-0 pa-2" cols="12" sm="12" md="10" lg="10">
                <v-btn-toggle
                  class="d-flex"
                  v-model="gender"
                  color="blue darken-2 accent-3"
                >
                  <v-btn value="F" class="flex-grow-1 options" outlined>
                    여성
                  </v-btn>
                  <v-btn value="M" class="flex-grow-1 options" outlined>
                    남성
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="waitingArea ma-0 px-4 py-2 pr-8"
            cols="12"
            sm="12"
            md="4"
            lg="4"
          >
            <v-card class="waitingInner pa-0 ma-0">
              <v-row class="ma-0 pa-0">
                <v-col class="waitingLabel" cols="12"> 현재 대기인원 </v-col>
              </v-row>
              <v-row class="waitingCon ma-0 pa-0">
                <v-col
                  class="waitingNow ml-xs-0 ml-sm-4 ml-md-4 ml-lg-4"
                  cols="12"
                >
                  <span class="waitingNum">{{ this.waitingPeopleNumber }}</span>
                  <span class="waitingTxt">팀</span>
                </v-col>
              </v-row>
              <v-row class="waitingFooter ma-0 pa-0">
                <v-col cols="2"></v-col>
                <v-col class="waitingFooterTxt" cols="8" align="center">
                  <span>{{ this.showSecond }}초 후 자동 새로고침</span>
                </v-col>
                <v-col class="waitingFooterIcon" cols="2">
                  <img
                    src="../assets/img/icon_reset.png"
                    alt="새로고침 아이콘"
                    class="resetImg"
                    @click="resetShowSecond"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="fullWidth infoAgree ma-0 px-4 py-6">
          <v-col class="mb-0 pa-0" cols="12" sm="12" md="6" lg="6">
            <v-row class="pa-0 px-4" align="center">
              <v-col cols="10" sm="12" md="auto" lg="auto">
                <span class="label">개인정보 수집 이용 동의</span>
                <span class="essential">*</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="pa-2" cols="2" sm="12" md="auto" lg="auto">
                <v-switch
                  v-model="isEnabledPersonalInfo"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="pa-0 px-4">
              <v-col class="pt-0" cols="12">
                <v-card
                  class="infoText overflow-y-auto pa-3 elevation-0"
                  max-height="130"
                >
                  - 수집.이용 목적: 전시장 내 상담서비스 제공, 입장알림
                  메시지전송(카카오톡) <br />
                  - 수집항목 : 이름, 인원, 연락처 <br />
                  - 보유.이용기간 : 입장일로부터 30일 <br />
                  - 위와 같이 본인의 고객정보를 수집하는 데 동의합니다.
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mb-0 pa-0" cols="12" sm="12" md="6" lg="6">
            <v-row class="pa-0 px-4" align="center">
              <v-col cols="10" sm="12" md="auto" lg="auto">
                <span class="label">마케팅 정보 수신 동의</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="pa-2" cols="2" sm="12" md="auto" lg="auto">
                <v-switch
                  v-model="isEnabledMarketingInfo"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="pa-0 px-4">
              <v-col class="pt-0" cols="12">
                <v-card
                  class="infoText overflow-y-auto pa-3 elevation-0"
                  max-height="130"
                >
                  - 수집.이용목적 : SMS를 통한 주요혜택 및 행사 정보 안내 <br />
                  - 수집 항목 : 이름, 휴대폰번호, 성별, 거주지, 연령대 <br />
                  - 보유.이용기간 : 1년 <br />
                  - 위와 같이 본인의 고객정보를 수집하는데 동의합니다.
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="4"></v-col>
        <v-col cols="12" sm="12" md="4" lg="4" align="center">
          <v-btn
            class="submitButton"
            color="#284e80"
            dark
            elevation="0"
            :loading="loadingButton"
            @click="apiRunApiFuncSubmit"
          >
            제출하기
          </v-btn>
          <v-btn
            class="resetButton"
            color="#cccccc"
            dark
            elevation="0"
            @click="reset"
          >
            초기화
          </v-btn>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
    </v-container>
    <v-container class="ma-0 pa-0" fluid>
      <div v-if="submitAlert" class="alertBackground">
        <v-alert
          class="submitAlert text-h6 text-sm-h3 text-md-h2 text-lg-h3"
          color="#284e80"
          dark
        >
          <div class="submitAlertTxt">
            <span>{{ this.alertPhoneNumber.substring(0, 3) }}-</span>
            <span>{{ this.alertPhoneNumber.substring(3, 7) }}-</span>
            <span>{{ this.alertPhoneNumber.substring(7, 11) }} 번호로</span>
            <br />
            <span>대기가 접수되었습니다</span>
          </div>
        </v-alert>
      </div>
      <div v-if="errorAlert" class="alertBackground">
        <v-alert
          class="errorAlert text-h6 text-sm-h4 text-md-h4 text-lg-h4"
          dark
        >
          <div class="errorAlertTxt">
            <span>에러가 발생했습니다</span>
            <br />
            <span
              class="errorBtn text-h6 text-sm-h6 text-md-h6 text-lg-h5"
              @click="refreshPage"
            >
              새로고침
            </span>
          </div>
        </v-alert>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import VueCookies from 'vue-cookies';
import * as ApiFunc from '@/common/apiFunction';

export default {
  data: () => ({
    locationName: '아주 오토리움 고양전시장',
    name: '',
    phoneNumber: '',
    alertPhoneNumber: '',
    peopleNumber: '',
    visitorType: '',
    age: null,
    gender: null,
    isEnabledPersonalInfo: false,
    isEnabledMarketingInfo: false,
    waitingPeopleNumber: '',
    showSecond: 60,
    timeInverter: null,
    submitAlert: false,
    errorAlert: false,
    loadingButton: false,
  }),
  created() {
    ApiFunc.runApiFunc(this.getWaitingPeopleNumber);
  },
  mounted() {
    this.startInterval();
  },
  beforeDestroy() {
    clearInterval(this.timeInverter);
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------ 현재 대기인원 관련 함수 --------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    startInterval() {
      this.timeInverter = setInterval(() => {
        this.showSecond = this.showSecond - 1;
        if (this.showSecond === 0) {
          this.showSecond = 60;
          ApiFunc.runApiFunc(this.getWaitingPeopleNumber);
        }
      }, 1000);
    },
    resetShowSecond() {
      ApiFunc.runApiFunc(this.getWaitingPeopleNumber);
      this.showSecond = 60;
    },
    async getWaitingPeopleNumber() {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/visitors/current`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }
        this.waitingPeopleNumber = res.data.data;
        return res;
      } catch (e) {
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------- 화면 초기화 함수 ------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    refreshPage() {
      this.reset();
      this.$router.go();
    },
    reset() {
      this.name = '';
      this.phoneNumber = '';
      this.peopleNumber = '';
      this.visitorType = '';
      this.age = null;
      this.gender = null;
      this.isEnabledPersonalInfo = false;
      this.isEnabledMarketingInfo = false;
      this.showSecond = 60;
      this.loadingButton = false;
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------- 제출 관련 함수 --------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    apiRunApiFuncSubmit() {
      if (this.name === '' || this.name === null || this.name === undefined) {
        alert('이름을 입력해주세요');
        return;
      }
      if (
        this.phoneNumber === '' ||
        this.phoneNumber === null ||
        this.phoneNumber === undefined
      ) {
        alert('연락처를 입력해주세요');
        return;
      }
      if (this.phoneNumber.length < 10) {
        alert('10~11자리 연락처를 입력해주세요');
        return;
      }
      if (
        this.peopleNumber === '' ||
        this.peopleNumber === null ||
        this.peopleNumber === undefined
      ) {
        alert('인원을 선택해주세요');
        return;
      }
      if (
        this.visitorType === '' ||
        this.visitorType === null ||
        this.visitorType === undefined
      ) {
        alert('방문유형을 선택해주세요');
        return;
      }
      if (this.isEnabledPersonalInfo === false) {
        alert('필수 동의 항목을 선택해주세요');
        return;
      }
      ApiFunc.runApiFunc(this.submit);
    },
    async submit() {
      try {
        if (this.loadingButton === true) {
          return;
        }
        const token = VueCookies.get('accessToken');

        this.loadingButton = true;
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/visitors`,
          {
            name: this.name,
            phoneNumber: this.phoneNumber,
            peopleNumber: this.peopleNumber + 1,
            visitorType: this.visitorType,
            age: this.checkAgeIsNull(),
            gender: this.gender,
            isEnabledPersonalInfo: this.isEnabledPersonalInfo,
            isEnabledMarketingInfo: this.isEnabledMarketingInfo,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status === 200) {
          this.alertPhoneNumber = this.phoneNumber;
          this.submitAlert = true;
          this.loadingButton = true;

          setTimeout(() => {
            this.submitAlert = false;
            this.alertPhoneNumber = '';
          }, 5000);

          window.scrollTo(0, 0);
          this.reset();
        }
        if (res.status !== 200) {
          this.errorAlert = true;

          setTimeout(() => {
            this.errorAlert = false;
            this.$router.go();
          }, 15000);

          this.reset();
          return res;
        }
        this.resetShowSecond();
        this.loadingButton = false;
        return res;
      } catch (e) {
        return e.response;
      }
    },
    checkAgeIsNull() {
      if (this.age === null) {
        return null;
      } else {
        return (this.age + 2) * 10;
      }
    },
  },
};
</script>

<style scoped>
/* Volvo Novum font */
@font-face {
  font-family: 'volvoNovum_medium';
  src: url('../assets/font/Volvo Novum-Medium.ttf');
}
/* Noto Sans font */
@font-face {
  font-family: 'NotoSansKR_medium';
  src: url('../assets/font/NotoSansKR-Medium.otf');
}
@font-face {
  font-family: 'NotoSansKR_regular';
  src: url('../assets/font/NotoSansKR-Regular.otf');
}
@font-face {
  font-family: 'NotoSansKR_light';
  src: url('../assets/font/NotoSansKR-Light.otf');
}
.mobile {
  display: block !important;
}
.containerArea {
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
}
.topArea {
  height: auto;
  text-align: center;
  background-color: #141414;
  min-height: 70px;
}
.topArea .logoName {
  color: #fff;
  font-size: 20pt;
}
.topArea .locationName {
  color: #bbbbbb;
  font-size: 15px;
  font-family: 'NotoSansKR_bold';
  vertical-align: middle;
}
.infoArea {
  color: #707070;
  font-size: 17px;
  font-family: 'NotoSansKR_regular';
  text-align: center;
}
.mainArea {
  border: 2px solid #cfcfcf;
  background-color: #fff;
}
.waitingArea {
  background-color: #fff;
}
.waitingArea .waitingInner {
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #272727;
  flex-direction: column;
}
.waitingArea .waitingNow {
  text-align: center;
}
.waitingArea .waitingFooter {
  align-items: center;
  background-color: #393939;
}
.waitingArea .waitingLabel {
  color: #bbbbbb;
  font-size: 12pt;
  text-align: center;
}
.waitingArea .waitingNum {
  color: #ffffff;
  font-weight: 600;
  font-size: 90pt;
  font-family: 'volvoNovum_medium';
}
.waitingArea .waitingFooterTxt {
  color: #bedbff;
  font-size: 15px;
  font-family: 'NotoSansKR_regular';
}
.waitingArea .waitingFooterIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.waitingArea .waitingTxt {
  color: #ffffff;
  font-weight: bold;
  font-size: 35pt;
  text-align: center;
}
p {
  padding: 0 !important;
  margin: 0 !important;
}
.inputs {
  height: 320px;
  border: 1px solid #e20e0e;
}
.input {
  align-items: center;
}
.infoAgree .infoText {
  border: 1px solid #e2e2e2;
  color: #707070 !important;
  font-size: 11pt;
  font-family: 'NotoSansKR_light';
}
.fullWidth {
  width: 100%;
}
.overflowScroll {
  overflow: auto;
}
.essential {
  color: #e20e0e;
}
.v-btn-toggle > button {
  height: 56px !important;
}
.v-btn-toggle > .v-btn--active {
  color: #1c6bba !important;
}
.v-btn-toggle > .v-item--active {
  border: 2px solid #1c6bba !important;
}
.resetButton {
  width: 100%;
  height: 80px !important;
  font-size: 3vh !important;
  font-family: 'NotoSansKR_medium' !important;
}
.submitButton {
  width: 100%;
  height: 80px !important;
  font-size: 3vh !important;
  margin-bottom: 20px !important;
}
.resetImg {
  width: 20px;
  height: auto;
}
.label {
  color: #3a3a3a;
  font-size: 18px;
  font-family: 'NotoSansKR_medium';
}
.options {
  color: #8b8b8b !important;
  font-size: 1.8vh !important;
  font-family: 'NotoSansKR_medium';
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.alertBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
.submitAlert {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 16%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0.95;
  align-items: center;
}
.submitAlertTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.errorAlert {
  position: fixed;
  top: -50px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 20%;
  margin: 0 auto;
  border: 1px solid #e20e0e !important;
  color: #e20e0e !important;
  font-weight: 600 !important;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #ffffffe5 !important;
  align-items: center;
  box-shadow: 2px 2px 6px #14141438 !important;
}
.errorAlertTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.errorBtn {
  display: block;
  width: 160px;
  padding: 5px;
  color: #ffffff;
  opacity: 1;
  background-color: #e20e0e;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
</style>
