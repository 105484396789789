import VueCookies from 'vue-cookies';
import Axios from 'axios';
import router from '../router';

export function runApiFunc(func) {
  func().then((res) => {
    if (400 <= res.status && res.status < 600) {
      if (res.data && res.data.statusCode === 999) {
        reissue().then(() => {
          func().then((res) => {
            if (res.status !== 200) {
              redirectLoginPage();
            }
          });
        });
      }
    }
  });
}
export function runApiFuncWithParams(func, value1, value2) {
  func(value1, value2).then((res) => {
    if (400 <= res.status && res.status < 600) {
      if (res.data && res.data.statusCode === 999) {
        reissue().then(() => {
          func(value1, value2).then((res) => {
            if (res.status !== 200) {
              redirectLoginPage();
            }
          });
        });
      }
    }
  });
}
export function runApiFuncWith4Params(func, value1, value2, value3, value4) {
  func(value1, value2, value3, value4).then((res) => {
    if (400 <= res.status && res.status < 600) {
      if (res.data && res.data.statusCode === 999) {
        reissue().then(() => {
          func(value1, value2, value3, value4).then((res) => {
            if (res.status !== 200) {
              redirectLoginPage();
            }
          });
        });
      }
    }
  });
}
export function runApiFuncAfterFunc(func, afterFunc) {
  func().then((res) => {
    if (200 <= res.status && res.status < 300) {
      afterFunc();
    }
    if (400 <= res.status && res.status < 600) {
      if (res.data && res.data.statusCode === 999) {
        reissue().then(() => {
          func().then((res) => {
            afterFunc();
            if (res.status !== 200) {
              redirectLoginPage();
            }
          });
        });
      }
    }
  });
}
export function runApiFuncAfterFuncForExcel(func, afterFunc) {
  func().then((res) => {
    if (200 <= res.status && res.status < 300) {
      afterFunc();
    }
    if (400 <= res.status && res.status < 600) {
      reissue().then(() => {
        func().then((res) => {
          afterFunc();
          if (res.status !== 200) {
            redirectLoginPage();
          }
        });
      });
    }
  });
}
//---------------------------------promise---------------------------------
export function runApiFuncWithPromise(func) {
  return new Promise(function (resolve, reject) {
    func().then((res) => {
      if (400 <= res.status && res.status < 600) {
        if (res.data && res.data.statusCode === 999) {
          reissue().then(() => {
            func().then((res) => {
              if (res.status !== 200) {
                redirectLoginPage();
                reject(new Error('ReLogin Please'));
              } else {
                resolve();
              }
            });
          });
        } else {
          reject(new Error('Request is failed'));
        }
      } else {
        resolve();
      }
    });
  });
}

async function reissue() {
  const accessToken = VueCookies.get('accessToken');
  const refreshToken = VueCookies.get('refreshToken');
  const res = await Axios.post(
    `${process.env.VUE_APP_API_URL}/api/auth/reissue`,
    {
      accessToken: accessToken,
      refreshToken: refreshToken,
    }
  );
  if (res.status !== 200) {
    return;
  }
  VueCookies.set('accessToken', res.data.data.accessToken, 0);
  VueCookies.set('refreshToken', res.data.data.refreshToken, 0);
}

function redirectLoginPage() {
  alert('다시 로그인 해주세요');
  deleteCookie('accessToken');
  deleteCookie('refreshToken');
  router.push('/login');
}

function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
