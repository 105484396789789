<!--
* 관리자 페이지
* 관리자가 고객 상태 관리하는 페이지
-->
<template>
  <v-app id="app">
    <v-container fluid>
      <v-row class="topArea" align="center">
        <v-col class="pl-8" cols="12" sm="12" md="12" lg="4" align="start">
          <img
            src="../assets/img/logo_volvo_black.png"
            alt="볼보 로고 이미지"
            class="logoImg"
          />
        </v-col>
        <v-col
          class="systemTitle"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          align="center"
          @click="refresh"
        >
          고객 대기관리 시스템
        </v-col>
        <v-col
          class="pr-8"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          align="end"
          :class="$vuetify.breakpoint.md === true ? 'tablet' : 'navZone'"
        >
          <span class="subTitle">아주오토리움 고양전시장</span>
          <span class="profile" @click="gotoSystemConfig">
            {{ currentRoleType }} / {{ currentName }}
          </span>
          <span>
            <img
              src="../assets/img/icon_logout.png"
              alt="로그아웃 아이콘"
              title="로그아웃"
              class="logoutButton pb-1"
              @click="logout"
            />
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row class="mb-1 mt-1 pl-3" align="center">
        <v-col cols="auto" class="pa-0">
          <v-btn color="#1976d2" dark elevation="0" @click="refreshCurrentInfo">
            TODAY({{ this.$moment(new Date(), 'YYYYMMDD').format('YY.MM.DD') }})
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-avatar class="ma-0 wait" size="12"></v-avatar>
          대기중: {{ currentInfo.wait }}
        </v-col>
        |
        <v-col cols="auto">
          <v-avatar class="ma-0 preview" size="12"></v-avatar>
          관람중: {{ currentInfo.preview }}
        </v-col>
        |
        <v-col cols="auto">
          <v-avatar class="ma-0 consult" size="12"></v-avatar>
          상담중: {{ currentInfo.consult }}
        </v-col>
        |
        <v-col cols="auto">
          <v-avatar class="ma-0 end" size="12"></v-avatar>
          종료: {{ currentInfo.end }}
        </v-col>
        |
        <v-col>
          <v-avatar class="ma-0 deny" size="12"></v-avatar>
          방문포기: {{ currentInfo.deny }}
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-divider></v-divider>
      <v-row class="align-md-start mt-1">
        <v-col cols="12" sm="12" md="6" lg="8">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-row align="center">
                <v-col class="pr-1" cols="2" lg="2" align="center">
                  from
                </v-col>
                <v-col class="px-1" cols="10" lg="10">
                  <DatePicker
                    class="widthM"
                    v-model="search.startDate"
                    type="date"
                    :clearable="false"
                    @change="applyFilterDate('start')"
                  ></DatePicker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-row align="center">
                <v-col class="px-1" cols="2" lg="1" align="center"> to </v-col>
                <v-col class="px-1" cols="10" lg="10">
                  <DatePicker
                    class="widthM"
                    v-model="search.endDate"
                    type="date"
                    :clearable="false"
                    @change="applyFilterDate('end')"
                  ></DatePicker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4">
              <v-row class="pr-5" align="center">
                <v-col class="px-0" cols="9">
                  <v-text-field
                    v-model="search.keyword"
                    placeholder="고객명, 연락처로 조회"
                    outlined
                    hide-details
                    dense
                    @keyup.enter="applyFilterSearch"
                  ></v-text-field>
                </v-col>
                <v-col class="px-1" cols="3" align="start">
                  <v-btn
                    class="searchButton elevation-0"
                    @click="applyFilterSearch"
                  >
                    검색
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4" aligne-md="start">
          <v-row align="center">
            <v-col class="px-1" cols="8" sm="8" md="5" lg="5">
              <v-select
                v-model="wizardWithAll.id"
                label="담당 위자드"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                dense
                :items="wizardWithAll"
                @change="applyFilterWizard(wizardWithAll.id)"
              ></v-select>
            </v-col>
            <v-col class="px-1" cols="8" sm="8" md="5" lg="5">
              <v-select
                v-model="defaultStatus.value"
                label="고객 상태"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                dense
                :items="filterStatus"
                @change="applyFilterStatus(defaultStatus.value)"
              ></v-select
            ></v-col>
            <v-col class="pa-0" cols="2" sm="2" md="1" lg="2" align="center">
              <img
                src="../assets//img/icon_reset.png"
                alt="테이블 새로고침 버튼"
                class="refreshButton"
                @click="refreshTable"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-3 pt-1">
      <v-row>
        <v-col cols="12">
          <v-data-table
            id="table"
            class="elevation-0"
            hide-default-footer
            mobile-breakpoint="0"
            :headers="headers"
            :items="allVisitor"
            :items-per-page="pagination.perPage"
            :loading="tableLoading"
          >
            <template slot="loading"> 데이터를 가져오고 있습니다. </template>
            <template slot="no-data"> 찾으시는 데이터가 없습니다. </template>
            <template v-slot:[`header.num`]>
              <button class="sortButton" @click="changeSortToggle">
                대기번호
                <v-icon v-if="sortToggle">mdi-arrow-up-thin</v-icon>
                <v-icon v-if="!sortToggle">mdi-arrow-down-thin</v-icon>
              </button>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.num }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.phoneNumber }}</td>
                <td>{{ item.peopleNumber }}</td>
                <td :class="{ highlight: item.visitorType == '계약희망' }">
                  {{ item.visitorType }}
                </td>
                <td>{{ item.age }}</td>
                <td>{{ item.gender }}</td>
                <td>{{ item.firstNotification }}</td>
                <td>{{ item.secondNotification }}</td>
                <td>
                  <v-select
                    v-model="item.wizard.id"
                    item-text="name"
                    item-value="id"
                    outlined
                    hide-details
                    dense
                    :items="wizard"
                    @change="apiRunApiFuncAssignWizard(item.id, item.wizard.id)"
                  >
                  </v-select>
                </td>
                <td>
                  <v-select
                    v-model="item.sales.id"
                    item-text="name"
                    item-value="id"
                    item-avartar
                    outlined
                    hide-details
                    dense
                    :items="sales"
                    @change="apiRunApiFuncAssignSales(item.id, item.sales.id)"
                  >
                  </v-select>
                </td>
                <td>
                  <v-select
                    v-model="item.status"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    dense
                    :disabled="
                      checkStatusButtonDisabled(item.wizard.id, item.sales.id)
                    "
                    :items="status"
                    @change="apiRunApiFuncAssignStatus(item.id, item.status)"
                  >
                    <template v-slot:selection="data">
                      <v-avatar
                        class="mr-3 statusColor"
                        :class="{
                          wait: data.item.text === '대기중',
                          preview: data.item.text === '관람중',
                          consult: data.item.text === '상담중',
                          end: data.item.text === '종료',
                          deny: data.item.text === '방문포기',
                        }"
                        size="12"
                      ></v-avatar>
                      <span v-text="data.item.text"></span>
                    </template>
                  </v-select>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-4 pb-4 pt-0" fluid>
      <v-row>
        <v-col cols="12" sm="12" md="2" lg="2" align="center">
          <v-select
            outlined
            hide-details
            dense
            v-model="pagination.perPage"
            :items="pagination.perPageList"
            @change="changePerPage"
          ></v-select>
        </v-col>
        <v-col cols="10" sm="10" md="8" lg="8" align="center">
          <v-pagination
            class="page"
            v-model="pagination.page"
            :length="pagination.length"
            :total-visible="10"
            @input="apiRunApiFuncFetchInit"
          ></v-pagination
        ></v-col>
        <v-col cols="12" sm="12" md="2" lg="2" align="end">
          <v-btn
            v-if="currentRoleType === '관리자'"
            class="excelButton"
            color="#ababab"
            dark
            :disabled="disableButton"
            :loading="buttonLoading"
            @click="apiRunApiFuncAfterFuncExcelDown"
          >
            엑셀 Download
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pt-5" justify="center">
        <v-alert
          v-if="addVisitorData"
          dismissible
          color="#1c6bba"
          text
          @input="changeAddVisitorData(false)"
        >
          새로운 고객 데이터가 {{ addVisitorDataHour }}시
          {{ addVisitorDataMinutes }}분에 등록되었습니다.
        </v-alert>
      </v-row>
      <v-row class="ma-0" justify="end">
        <v-alert
          :value="saveWizard"
          class="changeAlert"
          color="#ec6f38"
          text
          transition="scroll-y-reverse-transition"
        >
          담당 위자드({{ wizardName }})가 저장되었습니다.
        </v-alert>
        <v-alert
          :value="saveSales"
          class="changeAlert"
          color="#ec6f38"
          text
          transition="scroll-y-reverse-transition"
        >
          담당 영업사원({{ salesName }})이 저장되었습니다.
        </v-alert>
        <v-alert
          :value="saveStatus"
          class="changeAlert"
          color="#ec6f38"
          text
          transition="scroll-y-reverse-transition"
        >
          고객 상태가 변경되었습니다.
        </v-alert>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import VueCookies from 'vue-cookies';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as ApiFunc from '@/common/apiFunction';

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    allPageLoad: false,
    currentRoleType: null,
    currentName: null,
    disableButton: false,
    buttonLoading: false,
    visitor: [],
    allVisitor: [],
    tableLoading: false,
    headers: [
      {
        text: '대기번호',
        align: 'center',
        sortable: false,
        value: 'num',
        fixed: true,
        width: '60px',
      },
      {
        text: '고객명',
        align: 'center',
        sortable: false,
        value: 'name',
        width: '80px',
      },
      {
        text: '연락처',
        align: 'center',
        sortable: false,
        value: 'phoneNumber',
        width: '120px',
      },
      {
        text: '인원',
        align: 'center',
        sortable: false,
        value: 'peopleNumber',
        width: '60px',
      },
      {
        text: '방문유형',
        align: 'center',
        sortable: false,
        value: 'visitorType',
        width: '90px',
      },
      {
        text: '연령',
        align: 'center',
        sortable: false,
        value: 'age',
        width: '60px',
      },
      {
        text: '성별',
        align: 'center',
        sortable: false,
        value: 'gender',
        width: '60px',
      },
      {
        text: '1차 알림톡',
        align: 'center',
        sortable: false,
        value: 'firstNotification',
        width: '150px',
      },
      {
        text: '2차 알림톡',
        align: 'center',
        sortable: false,
        value: 'secondNotification',
        width: '150px',
      },
      {
        text: '담당 위자드',
        align: 'center',
        sortable: false,
        value: 'wizard',
        width: '150px',
      },
      {
        text: '담당 영업사원',
        align: 'center',
        sortable: false,
        value: 'sales',
        width: '150px',
      },
      {
        text: '상태',
        align: 'center',
        sortable: false,
        value: 'status',
        width: '160px',
      },
    ],
    wizard: [],
    wizardWithAll: [],
    sales: [],
    salesWithAll: [],
    visitorStatus: [],
    filterStatus: [],
    statusColor: '',
    status: [],
    defaultStatus: { text: '대기중', value: 'WAIT' },
    translation: {
      PREVIEW_HOPE: '관람희망',
      CONSULT_HOPE: '계약희망',
      F: '여',
      M: '남',
      ROLE_MANAGER: '매니저',
    },
    pagination: {
      page: 1,
      currentPage: 1,
      perPage: 10,
      list: [],
      length: 1,
      perPageList: [10, 20, 30, 50],
    },
    search: {
      startDate: new Date(),
      endDate: new Date(),
      date: [new Date(), new Date()],
      keyword: null,
      memberId: null,
      visitorStatus: 'WAIT',
    },
    eventSource: null,
    addVisitorData: false,
    addVisitorDataHour: '',
    addVisitorDataMinutes: '',
    startPos: 0,
    endPos: 0,
    offset: 0,
    distance: 0,
    currentInfo: {
      wait: '',
      preview: '',
      consult: '',
      end: '',
      deny: '',
    },
    sortToggle: true,
    sort: '',
    saveWizard: false,
    saveSales: false,
    saveStatus: false,
    wizardName: '',
    salesName: '',
  }),
  async mounted() {
    /**
     * ---순서도---
     * changeAllPageLoad : 모든 페이지가 로딩됬는지 - false
     * getRoleType : 로그인된 사용자 정보 가져오기
     * getStatus : 고객 상태값 가져오기
     * getWizard : 위자드 값 가져오기
     * getSales : 영업사원 값 가져오기
     * fetchInit : 테이블 값 가져오기
     * connectSse : Sse 연결하기
     * changeAllPageLoad : 모든 페이지가 로딩됬는지 - true
     */
    await this.changeAllPageLoad(false);
    await ApiFunc.runApiFuncWithPromise(this.getRoleType);
    await ApiFunc.runApiFuncWithPromise(this.getStatus);
    await ApiFunc.runApiFuncWithPromise(this.getWizard);
    await ApiFunc.runApiFuncWithPromise(this.getSales);
    await ApiFunc.runApiFuncWithPromise(this.getCurrentAll);
    await ApiFunc.runApiFuncWithPromise(this.fetchInit);
    await this.connectSse();
    await this.changeAllPageLoad(true);
    this.swipePage();
  },
  beforeDestroy() {
    this.eventSource.close();
  },
  watch: {
    saveWizard(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.saveWizard = false;
        }, 3000);
      }
    },
    saveSales(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.saveSales = false;
        }, 3000);
      }
    },
    saveStatus(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.saveStatus = false;
        }, 3000);
      }
    },
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //----------------------------- 화면 초기화 및 Sse, 이동 관련 함수 ----------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async connectSse() {
      try {
        const token = VueCookies.get('accessToken');

        let es = new EventSource(
          `${process.env.VUE_APP_API_URL}/api/sse/connect?token=` + token
        );

        es.addEventListener(
          'result',
          (e) => {
            if (e.data === 'visitorDetected') {
              this.addVisitorData = true;
              this.addVisitorDataHour = new Date().getHours();
              this.addVisitorDataMinutes = new Date().getMinutes();
              ApiFunc.runApiFunc(this.getCurrentAll);
            }
          },
          false
        );

        this.eventSource = es;
      } catch (e) {
        console.log(`Sse Error is "${e}"`);
      }
    },
    changeAddVisitorData(value) {
      this.addVisitorData = value;
    },
    async changeAllPageLoad(value) {
      this.allPageLoad = value;
    },
    gotoSystemConfig() {
      if (this.allPageLoad === false) {
        return;
      }
      if (this.currentRoleType === '관리자') {
        this.$router.push('/systemConfig');
      }
    },
    swipePage() {
      const table = document.getElementById('table');

      table.addEventListener('touchstart', (e) => {
        this.startPos = e.touches[0].pageX;
      });

      table.addEventListener('touchmove', (e) => {
        this.endPos = e.targetTouches[0].pageX;
        this.offset = this.endPos - this.startPos;
      });

      table.addEventListener('touchend', () => {
        this.distance = Math.abs(this.offset);
        const distanceNum = 500;

        if (this.distance > distanceNum && this.startPos > this.endPos) {
          if (this.pagination.page === this.pagination.length) {
            this.pagination.page = this.pagination.length;
            return;
          }
          this.pagination.page += 1;
          this.apiRunApiFuncFetchInit();
        } else if (this.distance > distanceNum && this.startPos < this.endPos) {
          if (this.pagination.page === 1) {
            this.pagination.page = 1;
            return;
          }
          this.pagination.page -= 1;
          this.apiRunApiFuncFetchInit();
        }
        this.startPos = 0;
        this.endPos = 0;
        this.offset = 0;
        this.distance = 0;
      });
    },
    refreshTable() {
      this.apiRunApiFuncFetchInit();
      this.getCurrentAll();
      this.buttonLoading = false;
    },
    refresh() {
      this.$router.go();
    },
    refreshCurrentInfo() {
      ApiFunc.runApiFunc(this.getCurrentAll);
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 토큰 체크를 위한 함수들 --------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    apiRunApiFuncFetchInit() {
      ApiFunc.runApiFunc(this.fetchInit);
    },
    apiRunApiFuncAssignWizard(rowId, wizardId) {
      ApiFunc.runApiFuncWithParams(this.assignWizard, rowId, wizardId);
    },
    apiRunApiFuncAssignSales(rowId, salesId) {
      ApiFunc.runApiFuncWithParams(this.assignSales, rowId, salesId);
    },
    apiRunApiFuncAssignStatus(rowId, status) {
      ApiFunc.runApiFuncWithParams(this.assignStatus, rowId, status);
    },
    apiRunApiFuncAfterFuncExcelDown() {
      ApiFunc.runApiFuncAfterFuncForExcel(
        this.excelDown,
        this.enableExcelButton
      );
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- mount시에 get 함수들 ---------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async getWizard() {
      try {
        this.wizardWithAll = [];
        this.wizard = [];

        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/members`,
          {
            headers: {
              Authorization: token,
            },
            params: {
              roleType: 'ROLE_WIZARD',
              size: 100,
              page: 0,
              memberStatus: 'VALID',
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        const resWizardsArray = res.data.data.content;
        let conversionWizardsArray = [];
        let conversionWizardsArrayWithAll = [
          { id: null, name: '전체', status: 'VALID' },
        ];

        for (let i = 0; i < resWizardsArray.length; i++) {
          let eachData = {
            id: resWizardsArray[i].id,
            name: resWizardsArray[i].name,
            status: resWizardsArray[i].memberStatus,
          };
          conversionWizardsArray.push(eachData);
          conversionWizardsArrayWithAll.push(eachData);
        }

        this.wizard = conversionWizardsArrayWithAll;
        this.wizardWithAll = conversionWizardsArrayWithAll;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getSales() {
      try {
        this.sales = [];
        this.salesWithAll = [];

        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/members`,
          {
            headers: {
              Authorization: token,
            },
            params: {
              roleType: 'ROLE_SALES',
              size: 100,
              page: 0,
              memberStatus: 'VALID',
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        const resSalesArray = res.data.data.content;
        let conversionSalesArray = [];
        let conversionSalesArrayWithAll = [
          { id: null, name: '전체', status: 'VALID' },
        ];

        for (let i = 0; i < resSalesArray.length; i++) {
          let eachData = {
            id: resSalesArray[i].id,
            name: resSalesArray[i].name,
            status: resSalesArray[i].memberStatus,
          };
          conversionSalesArray.push(eachData);
          conversionSalesArrayWithAll.push(eachData);
        }

        this.sales = conversionSalesArrayWithAll;
        this.salesWithAll = conversionSalesArrayWithAll;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getStatus() {
      try {
        this.status = [];
        this.filterStatus = [];
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/codes/visitor-status`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        let resSatatusArray = res.data.data;
        let conversionStatusArray = [];
        let conversionStatusArraywithAll = [{ text: '전체', value: null }];

        for (let i = 0; i < resSatatusArray.length; i++) {
          let eachData = {
            text: resSatatusArray[i].name,
            value: resSatatusArray[i].code,
          };
          conversionStatusArray.push(eachData);
          conversionStatusArraywithAll.push(eachData);
        }
        this.status = conversionStatusArray;
        this.filterStatus = conversionStatusArraywithAll;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getRoleType() {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/members/me`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }
        this.currentName = res.data.data.name;
        let loginMemberRoleType = res.data.data.roleType;
        if (loginMemberRoleType === 'ROLE_MANAGER') {
          this.currentRoleType = '관리자';
        } else if (loginMemberRoleType === 'ROLE_WIZARD') {
          this.currentRoleType = '위자드';
        } else if (loginMemberRoleType === 'ROLE_SALES') {
          this.currentRoleType = '영업사원';
        }
        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getCurrentAll() {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/visitors/current-all`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }
        this.currentInfo.wait = res.data.data[0].count;
        this.currentInfo.preview = res.data.data[1].count;
        this.currentInfo.consult = res.data.data[2].count;
        this.currentInfo.end = res.data.data[3].count;
        this.currentInfo.deny = res.data.data[4].count;
        return res;
      } catch (e) {
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ fetchInit과 fetchInit종속 함수들 ----------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async fetchInit() {
      try {
        this.tableLoading = true;
        this.allVisitor = [];
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/visitors`,
          {
            headers: {
              Authorization: token,
            },
            params: {
              startDateTime:
                this.$moment(this.search.startDate, 'YYYYMMDD').format(
                  'YYYY-MM-DD'
                ) + 'T00:00:00',
              endDateTime:
                this.$moment(this.search.endDate, 'YYYYMMDD').format(
                  'YYYY-MM-DD'
                ) + 'T23:59:59',
              keyword: this.search.keyword,
              visitorStatus: this.search.visitorStatus,
              memberId: this.search.memberId,
              page: this.pagination.page - 1,
              size: this.pagination.perPage,
              sort: this.sort,
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        if (res.data.data.totalElements > 0) {
          this.pagination.length = Math.ceil(
            res.data.data.totalElements / res.data.data.size
          );
        } else {
          this.pagination.length = 1;
        }

        const visitorInfo = res.data.data.content;
        this.allVisitor = [];
        for (let i = 0; i < visitorInfo.length; i++) {
          const visitorGroup = {
            id: visitorInfo[i].id,
            num: visitorInfo[i].waitNumber,
            name: visitorInfo[i].name,
            phoneNumber: this.formattingPhoneNumber(visitorInfo[i].phoneNumber),
            peopleNumber: this.formattingPeopleNumber(
              visitorInfo[i].peopleNumber
            ),
            visitorType: this.translation[visitorInfo[i].visitorType],
            age: this.formattingAge(visitorInfo[i].age),
            gender: this.translation[visitorInfo[i].gender],
            firstNotification: this.checkDataNull(
              this.$moment(visitorInfo[i].createdDate).format(
                'YY-MM-DD HH:mm:ss'
              )
            ),
            secondNotification: this.checkDataNull(
              this.$moment(visitorInfo[i].messageDate).format(
                'YY-MM-DD HH:mm:ss'
              )
            ),
            wizard: this.checkMemberNull(visitorInfo[i].wizardMember),
            sales: this.checkMemberNull(visitorInfo[i].salesMember),
            status: visitorInfo[i].visitorStatus,
          };
          this.visitor[i] = visitorGroup;
          this.allVisitor.push(this.visitor[i]);
        }
        this.tableLoading = false;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    checkDataNull(value) {
      let result = '';
      if (value === 'Invalid date' || value === undefined || value === null) {
        result = '';
      } else {
        result = value;
      }
      return result;
    },
    checkMemberNull(value) {
      let member = {
        id: null,
        name: '',
      };
      if (value === null || value === undefined) {
        return member;
      } else {
        if (value.id) {
          member.id = value.id;
          member.name = value.name;
          return member;
        } else {
          return member;
        }
      }
    },
    formattingPhoneNumber(value) {
      if (value.length === 10) {
        return (
          value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6)
        );
      } else {
        return (
          value.slice(0, 3) + '-' + value.slice(3, 7) + '-' + value.slice(7)
        );
      }
    },
    formattingAge(value) {
      if (value !== null) {
        return value + '대';
      }
    },
    formattingPeopleNumber(value) {
      if (value !== null) {
        return value + '명';
      }
    },
    logout() {
      this.deleteCookie('accessToken');
      this.deleteCookie('refreshToken');
      this.$router.push('/login');
    },
    deleteCookie(name) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    changePerPage() {
      this.pagination.page = 1;
      ApiFunc.runApiFunc(this.fetchInit);
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- 필터 관련 함수 ---------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    applyFilterDate(type) {
      this.pagination.page = 1;
      if (
        this.$moment(this.search.startDate, 'YYYYMMDD').format('YYYYMMDD') >
          this.$moment(this.search.endDate, 'YYYYMMDD').format('YYYYMMDD') &&
        type === 'end'
      ) {
        alert('시작일은 종료일보다 우선되어야 합니다.');
        this.search.startDate = this.search.endDate;
      } else if (
        this.$moment(this.search.startDate, 'YYYYMMDD').format('YYYYMMDD') >
          this.$moment(this.search.endDate, 'YYYYMMDD').format('YYYYMMDD') &&
        type === 'start'
      ) {
        alert('시작일은 종료일보다 우선되어야 합니다.');
        this.search.endDate = this.search.startDate;
      }

      ApiFunc.runApiFunc(this.fetchInit);
    },
    applyFilterWizard(num) {
      this.search.memberId = num;
      this.pagination.page = 1;
      ApiFunc.runApiFunc(this.fetchInit);
    },
    applyFilterStatus(status) {
      this.search.visitorStatus = status;
      this.pagination.page = 1;
      ApiFunc.runApiFunc(this.fetchInit);
      ApiFunc.runApiFunc(this.getCurrentAll);
    },
    applyFilterSearch() {
      this.pagination.page = 1;
      ApiFunc.runApiFunc(this.fetchInit);
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- 테이블 데이터 변경 함수 -------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async assignWizard(rowId, wizardId) {
      try {
        const token = VueCookies.get('accessToken');

        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/visitors/${rowId}/members`,
          {
            memberId: wizardId,
            roleType: 'ROLE_WIZARD',
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        let temp = this.wizard.findIndex((x) => x.id === wizardId);
        this.wizardName = this.wizard[temp].name;

        this.saveWizard = true;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    wizardTimeout() {},
    async assignSales(rowId, salesId) {
      try {
        const token = VueCookies.get('accessToken');

        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/visitors/${rowId}/members`,
          {
            memberId: salesId,
            roleType: 'ROLE_SALES',
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        let temp = this.sales.findIndex((x) => x.id === salesId);
        this.salesName = this.sales[temp].name;

        this.saveSales = true;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async assignStatus(rowId, status) {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/visitors/${rowId}/visitor-status`,
          {
            id: rowId,
            visitorStatus: status,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        this.apiRunApiFuncFetchInit();
        ApiFunc.runApiFunc(this.getCurrentAll);
        this.saveStatus = true;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    checkStatusButtonDisabled(wizardId, salesId) {
      if (wizardId === null && salesId === null) {
        return true;
      }
      return false;
    },
    changeSortToggle() {
      this.sortToggle = !this.sortToggle;
      if (this.sortToggle === true) {
        this.sort = 'createdDate,asc';
      } else if (this.sortToggle === false) {
        this.sort = 'createdDate,desc';
      }
      ApiFunc.runApiFunc(this.fetchInit);
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------- 엑셀 관련 함수들 ------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async excelDown() {
      try {
        this.buttonLoading = true;
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/excel/visitors`,
          {
            headers: {
              Authorization: token,
            },
            params: {
              startDateTime:
                this.$moment(this.search.startDate, 'YYYYMMDD').format(
                  'YYYY-MM-DD'
                ) + 'T00:00:00',
              endDateTime:
                this.$moment(this.search.endDate, 'YYYYMMDD').format(
                  'YYYY-MM-DD'
                ) + 'T23:59:59',
              keyword: this.search.keyword,
              visitorStatus: this.search.visitorStatus,
              memberId: this.search.memberId,
            },
            responseType: 'blob',
          }
        );

        if (res.status !== 200) {
          return res;
        }

        const fileNameWithKeyValue = this.getFileName(
          res.headers['content-disposition']
        );
        const fileName = fileNameWithKeyValue.split('=')[1];

        const blob = new Blob([res.data], {
          type: 'application/octet-stream',
        });
        const link = document.createElement('a');
        const blobUrl = URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(blobUrl);

        return res;
      } catch (e) {
        if (e.response.status === 400) {
          alert('1년 이내의 데이터만 다운받을 수 있습니다.');
          this.restrictDate();
          this.refreshTable();
        }
        return e.response;
      }
    },
    getFileName(contentDisposition) {
      let fileName = contentDisposition.split(';').filter((ele) => {
        return ele.indexOf('filename') > -1;
      });
      return fileName[0] ? fileName[0] : null;
    },
    enableExcelButton() {
      this.buttonLoading = false;
    },
    restrictDate() {
      this.search.endDate = this.$moment(this.search.startDate)
        .add(1, 'year')
        .subtract(1, 'day')._d;
    },
  },
};
</script>

<style>
/* Noto Sans font */
@font-face {
  font-family: 'NotoSansKR_bold';
  src: url('../assets/font/NotoSansKR-Bold.otf');
}
@font-face {
  font-family: 'NotoSansKR_regular';
  src: url('../assets/font/NotoSansKR-Regular.otf');
}
@font-face {
  font-family: 'NotoSansKR_light';
  src: url('../assets/font/NotoSansKR-Light.otf');
}
.mobile {
  display: flex;
}
.tablet {
  display: flex;
  flex-direction: column;
}
.topArea {
  height: auto;
  border-bottom: #bebebe solid 1px;
  background-color: #fafafa;
}
.topArea .logoImg {
  width: 150px;
  height: auto;
}
.topArea .systemTitle {
  color: #3a3a3a;
  font-weight: bold;
  font-size: 28px;
  font-family: 'NotoSansKR_bold';
}
.topArea .navZone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.topArea .subTitle {
  font-size: 16px;
  font-family: 'NotoSansKR_bold';
  padding-right: 5px;
}
.topArea .profile {
  color: #1976d2;
  font-size: 16px;
  font-family: 'NotoSansKR_bold';
  padding-right: 5px;
  vertical-align: middle;
}
.topArea .logoutButton {
  position: relative;
  top: 6px;
  width: 20px;
  height: auto;
}
.mx-input {
  border: 1px solid rgba(0, 0, 0, 0.42);
  min-height: 40px;
}
.mx-datepicker-range {
  max-width: 230px;
}
.searchButton {
  border: 1px solid #bebebe;
  color: #707070;
  background-color: #e8e8e8;
}
.refreshButton {
  width: 25px;
  height: auto;
}
.v-data-table-header {
  color: #3a3a3a;
  text-align: center;
  background-color: #fafafa;
}
.v-data-table-header th {
  font-size: 14px !important;
  font-family: 'NotoSansKR_regular' !important;
  border-top: solid #1976d2;
}
.v-data-table td {
  color: #707070;
  font-family: 'NotoSansKR_light';
  text-align: center;
  border-bottom: #bebebe solid 1px;
}
.hiddleTableData {
  display: none;
}
.v-data-table tr:nth-last-child(odd) {
  background-color: #fafafa;
}
.statusColor {
  border: 1px solid lightgrey;
  border-radius: 50%;
}
.v-select__selection {
  color: #707070;
  font-size: 14px;
}
.v-select__selections > span {
  color: #707070;
  font-size: 14px;
}
.wait {
  background-color: #ffb400;
}
.preview {
  background-color: #79ac47;
}
.consult {
  background-color: #70bcf1;
}
.end {
  background-color: #acacac;
}
.deny {
  background-color: #ec6f38;
}
.footer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.footer > .excelButton {
  position: fixed;
  right: 0;
  font-size: 14px;
  font-family: 'NotoSansKR_regular';
  margin-right: 32px;
}
.widthM {
  width: 100%;
}
.highlight {
  color: #1976d2 !important;
  font-weight: bold;
}
.changeAlert {
  margin-left: 10px;
}
</style>
