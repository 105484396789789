<!--
* 팀당 대기 시간 수정하는 모달
-->
<template>
  <v-card>
    <v-card-title class="pb-4">
      <span>팀당 대기시간 변경</span>
      <v-btn icon dark @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-4">
      <v-divider color="#3a3a3a"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label" cols="4" sm="3" md="3" lg="3">대기시간</v-col>
        <v-col cols="8" sm="9" md="9" lg="9">
          <v-text-field
            v-model="waitingTime"
            placeholder="대기시간을 입력해주세요"
            type="number"
            oninput="javascript: 
                      this.value = this.value.replace(/[^0-9]/g, '');
                      if(this.value.length > 3){
                        this.value = this.value.substring(0,3);
                      };"
            outlined
            hide-details
            dense
            full-width
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center buttons pb-6">
      <v-col cols="6" align="center">
        <v-btn
          color="#284e80"
          dark
          width="90%"
          elevation="0"
          @click="updateWaiting"
        >
          변경
        </v-btn>
      </v-col>
      <v-col cols="6" align="center">
        <v-btn color="#e6e6e6" width="90%" elevation="0" @click="closeModal">
          취소
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueCookies from 'vue-cookies';
import * as ApiFunc from '@/common/apiFunction';

export default {
  props: {
    systemSettingId: Number,
  },
  data: () => ({
    waitingTime: '',
  }),
  mounted() {
    this.getWaitingTime();
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- mount시에 get 함수들 ---------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    getWaitingTime() {
      ApiFunc.runApiFunc(this.getWaitingTimeApi);
    },
    async getWaitingTimeApi() {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/system-setting`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        if (res.data.data === null) {
          this.waitingTime = 1;
        } else {
          this.waitingTime = res.data.data.expectedWaitTime;
        }

        return res;
      } catch (e) {
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 관리자 생성 및 수정과 종속 함수들 -----------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    updateWaiting() {
      ApiFunc.runApiFuncAfterFunc(this.updateWaitingApi, this.closeModal);
    },
    async updateWaitingApi() {
      try {
        const token = VueCookies.get('accessToken');
        if (this.waitingTime < 1) {
          alert('최소 대기 시간은 1분 입니다.');
          this.waitingTime = 1;
        }
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/system-setting`,
          {
            systemSettingId: this.systemSettingId,
            expectedWaitTime: this.waitingTime,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        this.$emit('updateWaiting');
        if (res.status !== 200) {
          return res;
        }
        return res;
      } catch (e) {
        if (e.response.data.message) {
          alert(e.response.data.message);
        }
        this.resetData();
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 모달 관련 함수 ----------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    closeModal() {
      this.resetData();
      this.$emit('closeModal');
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 데이터 리셋 함수 --------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    resetData() {
      this.waitingTime = '';
    },
  },
};
</script>

<style scoped>
.v-card__title {
  display: flex;
  color: #fff;
  justify-content: space-between;
  background-color: #3a3a3a;
}
.label {
  color: #3a3a3a;
}
.buttons > button {
  width: 150px;
  font-size: 16px;
}
.buttons > button:nth-child(2) {
  color: #707070;
}
</style>
