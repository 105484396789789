<!--
* 대시보드 페이지
* 고객 페이지 또는 관리자 페이지로 분기하는 페이지
-->
<template>
  <v-row class="template">
    <v-col v-if="roleType === 'ROLE_USER'" cols="6">
      <router-link router-link to="customer">
        <v-btn>고객 페이지</v-btn>
      </router-link>
    </v-col>
    <v-col v-if="roleType !== 'ROLE_USER'" cols="6">
      <router-link to="manager">
        <v-btn>관리자 페이지</v-btn>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
  data: () => ({
    roleType: null,
  }),
  mounted() {
    this.fetchInit();
    this.redirectionByRoleType();
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------- 로그인 및 리다이렉션 관련 함수 -----------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    fetchInit() {
      this.checkLogin();
      this.checkRoleType();
    },
    checkLogin() {
      const accessToken = VueCookies.get('accessToken');
      const refreshToken = VueCookies.get('refreshToken');
      if (accessToken === null || refreshToken === null) {
        this.$router.push('/login');
      }
    },
    checkRoleType() {
      const token = VueCookies.get('accessToken');
      let decoded = this.$jwtDecode(token);
      this.roleType = decoded.roleType;
    },
    redirectionByRoleType() {
      switch (this.roleType) {
        case 'ROLE_USER':
          this.$router.push('/customer');
          break;
        case 'ROLE_MANAGER':
          this.$router.push('/manager');
          break;
        case 'ROLE_WIZARD':
          this.$router.push('/manager');
          break;
        case 'ROLE_SALES':
          this.$router.push('/manager');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.template {
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
</style>
