<!--
* 관리자(위자드, 영업사원) 생성 및 수정하는 모달
-->
<template>
  <v-card>
    <v-card-title v-if="!rowId" class="pb-4">
      <span>관리자 생성</span>
      <v-btn icon dark @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title v-if="rowId">
      <span>관리자 수정</span>
      <v-btn icon dark @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-4">
      <v-divider color="#3a3a3a"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label" cols="4" sm="3" md="3" lg="3">이름</v-col>
        <v-col cols="8" sm="9" md="9" lg="9">
          <v-text-field
            v-model="managerName"
            placeholder="이름을 입력해주세요"
            outlined
            hide-details
            dense
            full-width
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider color="#e6e6e6"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label pb-0 mb-0" cols="4" sm="3" md="3" lg="3">
          사용자ID
        </v-col>
        <v-col class="pb-0 mb-0" cols="8" sm="9" md="9" lg="9">
          <v-text-field
            v-if="!rowId"
            v-model="managerId"
            placeholder="아이디를 입력해주세요"
            oninput="javascript: 
                      this.value = this.value.replace(/[^a-z|A-Z|0-9]/g, '');"
            outlined
            hide-details
            dense
            full-width
          ></v-text-field>
          <v-text-field
            v-if="rowId"
            v-model="this.managerId"
            disabled
            outlined
            hide-details
            dense
            full-width
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="py-0 my-0">
        <v-col class="py-0 my-0" cols="4" sm="3" md="3" lg="3"></v-col>
        <v-col
          v-if="checkIdValidation() === 'notValidate'"
          class="py-0 my-0 red--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          * 아이디는 영문, 숫자 4~20자리 입력해주세요.
        </v-col>
        <v-col
          v-else-if="checkIdValidation() === 'blank'"
          class="py-0 my-0 grey--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          * 아이디를 입력해 주세요.
        </v-col>
        <v-col
          v-else-if="checkIdValidation() === 'disable'"
          class="py-0 my-0 grey--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          * 아이디는 변경할 수 없습니다.
        </v-col>
        <v-col
          v-else-if="checkIdValidation() === 'writing'"
          class="py-0 my-0 grey--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          &nbsp;
        </v-col>
      </v-row>
      <v-divider color="#e6e6e6"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label" cols="4" sm="3" md="3" lg="3">비밀번호</v-col>
        <v-col cols="8" sm="9" md="9" lg="9">
          <v-text-field
            v-if="!rowId"
            v-model="managerPw"
            placeholder="비밀번호를 입력해주세요."
            outlined
            hide-details
            dense
            full-width
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-if="rowId"
            v-model="managerPw"
            placeholder="변경을 원하면 비밀번호를 입력해주세요."
            outlined
            hide-details
            dense
            full-width
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider color="#e6e6e6"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label pb-0 mb-0" cols="4" sm="3" md="3" lg="3">
          비밀번호 확인
        </v-col>
        <v-col class="pb-0 mb-0" cols="8" sm="9" md="9" lg="9">
          <v-text-field
            v-if="!rowId"
            v-model="checkManagerPw"
            placeholder="비밀번호를 입력해주세요."
            type="password"
            outlined
            hide-details
            dense
            full-width
          ></v-text-field>
          <v-text-field
            v-if="rowId"
            v-model="checkManagerPw"
            placeholder="변경을 원하면 비밀번호를 입력해주세요."
            type="password"
            outlined
            hide-details
            dense
            full-width
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="py-0 my-0">
        <v-col class="py-0 my-0" cols="4" sm="3" md="3" lg="3"></v-col>
        <v-col
          v-if="checkPasswordCorrect() === 'correct'"
          class="py-0 my-0 green--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          * 비밀번호가 일치합니다.
        </v-col>
        <v-col
          v-else-if="checkPasswordCorrect() === 'notCorrect'"
          class="py-0 my-0 red--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          * 비밀번호가 다릅니다.
        </v-col>
        <v-col
          v-else-if="checkPasswordCorrect() === 'blank'"
          class="py-0 my-0 grey--text"
          cols="8"
          sm="9"
          md="9"
          lg="9"
        >
          * 비밀번호를 입력해 주세요.
        </v-col>
      </v-row>
      <v-divider color="#e6e6e6"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label" cols="4" sm="3" md="3" lg="3">구분</v-col>
        <v-col cols="8" sm="9" md="9" lg="9">
          <v-select
            v-model="managerRoleType"
            label="구분을 선택하세요."
            item-text="text"
            item-value="value"
            outlined
            hide-details
            dense
            :items="roleType"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider color="#e6e6e6"></v-divider>
      <v-row class="pt-3 pb-3" align="center">
        <v-col class="label" cols="4" sm="3" md="3" lg="3">상태</v-col>
        <v-col cols="8" sm="9" md="9" lg="9">
          <v-select
            v-model="managerStatus"
            label="상태를 선택하세요."
            item-text="text"
            item-value="value"
            outlined
            hide-details
            dense
            :items="status"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider color="#e6e6e6"></v-divider>
    </v-card-text>
    <v-card-actions class="justify-center buttons pb-6">
      <v-col cols="6" align="center">
        <v-btn
          v-if="!rowId"
          width="90%"
          color="#284e80"
          dark
          elevation="0"
          @click="createManager"
        >
          생성
        </v-btn>
        <v-btn
          v-if="rowId"
          width="90%"
          color="#284e80"
          dark
          elevation="0"
          @click="updateManager"
        >
          수정
        </v-btn>
      </v-col>
      <v-col cols="6" align="center">
        <v-btn width="90%" color="#e6e6e6" elevation="0" @click="closeModal">
          취소
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueCookies from 'vue-cookies';
import * as ApiFunc from '@/common/apiFunction';

export default {
  props: {
    rowId: Number,
  },
  data: () => ({
    managerName: '',
    managerId: '',
    managerPw: '',
    checkManagerPw: '',
    managerRoleType: '',
    managerStatus: '',
    roleType: [],
    status: [],
    showPassword: false,
  }),
  watch: {
    rowId() {
      this.fetchInit();
    },
  },
  mounted() {
    ApiFunc.runApiFunc(this.getRoleType);
    this.fetchInit();
    this.getStatus();
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- 입력값 validate 함수들 -------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    checkPasswordCorrect() {
      if (this.checkManagerPw === '') {
        return 'blank';
      }
      if (this.managerPw === this.checkManagerPw) {
        return 'correct';
      } else {
        return 'notCorrect';
      }
    },
    checkIdValidation() {
      if (this.managerId === '') {
        return 'blank';
      }
      if (this.managerId.length < 4 || this.managerId.length > 21) {
        return 'notValidate';
      }
      if (this.rowId && this.managerId) {
        return 'disable';
      }
      if (!this.rowId && this.managerId) {
        return 'writing';
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- mount시에 get 함수들 ---------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async getRoleType() {
      try {
        this.filterStatus = [];
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/codes/role-type`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        let resRoleTypeArray = res.data.data;
        let conversionRoleTypeArray = [];

        for (let i = 0; i < resRoleTypeArray.length; i++) {
          let eachData = {
            text: resRoleTypeArray[i].name,
            value: resRoleTypeArray[i].code,
          };
          conversionRoleTypeArray.push(eachData);
        }

        this.roleType = conversionRoleTypeArray;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getStatus() {
      try {
        this.status = [];
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/codes/member-status`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        let tempStatusArray = [];

        let memberStatus = res.data.data;
        for (let i = 0; i < memberStatus.length; i++) {
          let eachData = {
            text: memberStatus[i].name,
            value: memberStatus[i].code,
          };
          tempStatusArray.push(eachData);
        }
        this.status = tempStatusArray;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ fetchInit과 fetchInit종속 함수들 ----------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async fetchInit() {
      const token = VueCookies.get('accessToken');
      if (this.rowId !== null) {
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/members/${this.rowId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const memberInfo = res.data.data;
        this.managerName = memberInfo.name;
        this.managerId = memberInfo.username;
        this.managerRoleType = memberInfo.roleType;
        this.managerStatus = memberInfo.memberStatus;
      } else if (this.rowId === null) {
        this.resetData();
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 관리자 생성 및 수정과 종속 함수들 -----------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    createManager() {
      ApiFunc.runApiFuncAfterFunc(this.createManagerApi, this.closeModal);
    },
    updateManager() {
      if (this.managerPw) {
        ApiFunc.runApiFuncAfterFunc(this.updateManagerPwApi, this.closeModal);
      } else {
        ApiFunc.runApiFuncAfterFunc(this.updateManagerApi, this.closeModal);
      }
    },
    async createManagerApi() {
      if (this.managerName == '') {
        alert('이름을 입력해주세요.');
      } else if (this.managerId === '') {
        alert('아이디를 입력해주세요.');
      } else if (this.managerPw === '') {
        alert('비밀번호를 입력해주세요.');
      } else if (this.managerRoleType === '') {
        alert('구분을 선택해주세요.');
      } else if (this.managerStatus === '') {
        alert('상태를 선택해주세요.');
      } else {
        try {
          const token = VueCookies.get('accessToken');

          if (this.managerPw && this.managerPw !== this.checkManagerPw) {
            alert('비밀번호가 일치하지 않습니다.');
            this.checkManagerPw = '';
            return;
          }

          const res = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}/api/members`,
            {
              name: this.managerName,
              username: this.managerId,
              password: this.managerPw,
              memberStatus: this.managerStatus,
              roleType: this.managerRoleType,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          );

          this.$emit('updateMemberInfo');
          if (res.status !== 200) {
            return res;
          }

          this.resetData();
          return res;
        } catch (e) {
          if (e.response.data.message) {
            alert(e.response.data.message);
            return;
          }
          this.resetData();
          return e.response;
        }
      }
    },
    async updateManagerPwApi() {
      // '비밀번호'와 '그외 정보' 각각 update하는 api가 분리되어있음
      try {
        const token = VueCookies.get('accessToken');

        if (this.managerPw && this.managerPw !== this.checkManagerPw) {
          alert('비밀번호가 일치하지 않습니다.');
          this.checkManagerPw = '';
          return;
        }

        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/members/${this.rowId}/password`,
          {
            password: this.managerPw,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        this.updateManagerApi();
        this.resetData();
        return res;
      } catch (e) {
        if (e.response.data.message) {
          alert(e.response.data.message);
        }
        this.resetData();
        return e.response;
      }
    },
    async updateManagerApi() {
      // '비밀번호'와 '그외 정보' 각각 update하는 api가 분리되어있음
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/members/${this.rowId}`,
          {
            name: this.managerName,
            username: this.managerId,
            memberStatus: this.managerStatus,
            roleType: this.managerRoleType,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        this.$emit('updateMemberInfo');
        if (res.status !== 200) {
          return res;
        }
        this.resetData();
        return res;
      } catch (e) {
        if (e.response.data.message) {
          alert(e.response.data.message);
        }
        this.resetData();
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 모달 관련 함수 ----------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    closeModal() {
      this.resetData();
      this.$emit('closeModal', this.rowId);
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ 데이터 리셋 함수 --------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    resetData() {
      this.managerName = '';
      this.managerId = '';
      this.managerPw = '';
      this.managerRoleType = '';
      this.managerStatus = '';
    },
  },
};
</script>

<style scoped>
.v-card__title {
  display: flex;
  color: #fff;
  justify-content: space-between;
  background-color: #3a3a3a;
}
.label {
  color: #3a3a3a;
}
.buttons > button {
  width: 150px;
  font-size: 16px;
}
.buttons > button:nth-child(2) {
  color: #707070;
}
</style>
