<!--
  * Vue 애플리케이션의 최상위 컴포넌트
  * Vue2 사용
  * <router-view></router-view>로 라우터로 지정된 컴포넌트를 표시
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
@font-face {
  font-family: 'NotoSansKR_medium';
  src: url('./assets/font/NotoSansKR-Medium.otf');
}
#app {
  font-family: 'NotoSansKR_medium';
}
html,
body {
  font-family: 'NotoSansKR_medium';
}
</style>
