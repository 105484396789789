<!--
* 시스템설정 페이지
* 매니저(최고 권한자)가 관리자(위자드, 영업사원) 생성 및 상태 관리하는 페이지
-->
<template>
  <v-app id="app">
    <v-container fluid>
      <v-row class="topArea" align="center">
        <v-col class="pl-8" cols="12" sm="12" md="12" lg="4" align="start">
          <img
            src="../assets/img/logo_volvo_black.png"
            alt="볼보 로고 이미지"
            class="logoImg"
          />
        </v-col>
        <v-col
          class="systemTitle"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          align="center"
          @click="refresh"
        >
          고객 대기관리 시스템
        </v-col>
        <v-col
          class="pr-8"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          align="end"
          :class="$vuetify.breakpoint.md === true ? 'tablet' : 'navZone'"
        >
          <span class="subTitle">아주오토리움 고양전시장</span>
          <span class="profile" @click="gotoManager">
            {{ currentRoleType }} / {{ currentName }}
          </span>
          <span>
            <img
              src="../assets/img/icon_logout.png"
              alt="로그아웃 아이콘"
              class="logoutButton pb-1"
              title="로그아웃"
              @click="logout"
            />
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-8" fluid>
      <v-row align="center">
        <v-col class="pr-0" cols="auto">
          <v-text-field
            v-model="search.keyword"
            placeholder="텍스트 조회"
            outlined
            hide-details
            dense
            style="width: 230px"
            @keyup.enter="applyFilter"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-btn class="searchButton elevation-0" @click="applyFilter">
            검색
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pr-0" cols="auto" align="end">
          현재 팀당 대기시간: {{ waitingPerTeam }}분
        </v-col>
        <v-col cols="auto" align="center">
          <v-btn class="searchButton elevation-0" @click="openWaitingModal">
            변경
          </v-btn>
        </v-col>
        <v-col cols="auto" align="end">
          <v-btn class="createButton" color="#284e80" dark @click="openModal">
            관리자 생성
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="tableArea">
        <v-col cols="12">
          <v-data-table
            id="table"
            hide-default-footer
            mobile-breakpoint="0"
            :headers="headers"
            :items="allMember"
            :loading="tableLoading"
          >
            <template slot="loading"> 데이터를 불러오고 있습니다. </template>
            <template slot="no-data"> 찾으시는 데이터가 없습니다. </template>
            <template v-slot:item="{ item }">
              <tr>
                <td @click="gotoDetail(item.id)">{{ item.num }}</td>
                <td @click="gotoDetail(item.id)">{{ item.name }}</td>
                <td @click="gotoDetail(item.id)">{{ item.username }}</td>
                <td @click="gotoDetail(item.id)">
                  {{ translation[item.roleType] }}
                </td>
                <td>
                  <v-select
                    v-model="item.status"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    dense
                    :items="status"
                    @change="
                      apiRunApiFuncAssignStatus(
                        item.id,
                        item.status,
                        item.name,
                        item.roleType
                      )
                    "
                  ></v-select>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="bottomArea">
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.length"
            :total-visible="10"
            @input="apiRunApiFuncFetchInit"
          ></v-pagination>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="createMember" min-width="310px" max-width="450px">
      <CreateModal
        v-if="createMember"
        :rowId="rowId"
        @updateMemberInfo="updateMemberInfo"
        @closeModal="closeModal"
      ></CreateModal>
    </v-dialog>
    <v-dialog v-model="changeWaiting" min-width="310px" max-width="450px">
      <WaitingModal
        v-if="changeWaiting"
        max-width="40%"
        :systemSettingId="systemSettingId"
        @updateWaiting="updateWaiting"
        @closeModal="closeModal"
      ></WaitingModal>
    </v-dialog>
  </v-app>
</template>

<script>
import VueCookies from 'vue-cookies';
import CreateModal from '@/components/SystemConfigModal.vue';
import WaitingModal from '@/components/SystemConfigWaitingModal.vue';
import * as ApiFunc from '@/common/apiFunction';

export default {
  components: {
    CreateModal,
    WaitingModal,
  },
  data: () => ({
    allPageLoad: false,
    mobile: 960,
    currentRoleType: null,
    currentName: null,
    member: [],
    allMember: [],
    tableLoading: false,
    headers: [
      {
        text: 'NO.',
        align: 'center',
        sortable: false,
        value: 'num',
        width: '5%',
      },
      {
        text: '이름',
        align: 'center',
        sortable: false,
        value: 'name',
        width: '120px',
      },
      {
        text: '사용자ID',
        align: 'center',
        sortable: false,
        value: 'username',
        width: '100px',
      },
      {
        text: '구분',
        align: 'center',
        sortable: false,
        value: 'roleType',
        width: '120px',
      },
      {
        text: '상태',
        align: 'center',
        sortable: false,
        value: 'status',
        width: '130px',
      },
    ],
    status: [],
    defaultStatus: true,
    createMember: false,
    translation: {
      ROLE_MANAGER: '관리자',
      ROLE_USER: '고객화면전용',
      ROLE_WIZARD: '위자드',
      ROLE_SALES: '영업사원',
    },
    pagination: {
      page: 1,
      currentPage: 1,
      perPage: 10,
      list: [],
      length: 1,
    },
    search: {
      keyword: null,
    },
    rowId: null,
    changeWaiting: false,
    systemSettingId: null,
    waitingPerTeam: null,
    startPos: 0,
    endPos: 0,
    offset: 0,
    distance: 0,
  }),
  async mounted() {
    /**
     * ---순서도---
     * changeAllPageLoad : 모든 페이지가 로딩됬는지 - false
     * getRoleType : 로그인된 사용자 정보 가져오기
     * getWaiting : 현재 대기 기본 시간 가져오기
     * getStatus : 관리자 상태값 가져오기
     * fetchInit : 테이블 값 가져오기
     * changeAllPageLoad : 모든 페이지가 로딩됬는지 - true
     */
    await this.changeAllPageLoad(false);
    await ApiFunc.runApiFuncWithPromise(this.getRoleType);
    await ApiFunc.runApiFuncWithPromise(this.getWaiting);
    await ApiFunc.runApiFuncWithPromise(this.getStatus);
    await ApiFunc.runApiFuncWithPromise(this.fetchInit);
    await this.changeAllPageLoad(true);
    this.swipePage();
  },
  updated() {
    this.updateWaiting();
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------- 화면 초기화 및 이동 관련 함수 ------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async changeAllPageLoad(value) {
      this.allPageLoad = value;
    },
    swipePage() {
      const table = document.getElementById('table');

      table.addEventListener('touchstart', (e) => {
        this.startPos = e.touches[0].pageX;
      });

      table.addEventListener('touchmove', (e) => {
        this.endPos = e.targetTouches[0].pageX;
        this.offset = this.endPos - this.startPos;
      });

      table.addEventListener('touchend', () => {
        this.distance = Math.abs(this.offset);
        const distanceNum = 500;

        if (this.distance > distanceNum && this.startPos > this.endPos) {
          if (this.pagination.page === this.pagination.length) {
            this.pagination.page = this.pagination.length;
            return;
          }
          this.pagination.page += 1;
          this.apiRunApiFuncFetchInit();
        } else if (this.distance > distanceNum && this.startPos < this.endPos) {
          if (this.pagination.page === 1) {
            this.pagination.page = 1;
            return;
          }
          this.pagination.page -= 1;
          this.apiRunApiFuncFetchInit();
        }
        this.startPos = 0;
        this.endPos = 0;
        this.offset = 0;
        this.distance = 0;
      });
    },
    refresh() {
      this.$router.go();
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------ fetchInit과 fetchInit종속 함수들 ----------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    async fetchInit() {
      try {
        this.tableLoading = true;
        this.allMember = [];
        const token = VueCookies.get('accessToken');

        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/members`,
          {
            headers: {
              Authorization: token,
            },
            params: {
              keyword: this.search.keyword,
              page: this.pagination.page - 1,
              size: this.pagination.perPage,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        if (res.data.data.totalElements > 0) {
          this.pagination.length = Math.ceil(
            res.data.data.totalElements / res.data.data.size
          );
        }

        const memberInfo = res.data.data.content;
        for (let i = 0; i < memberInfo.length; i++) {
          const memberGroup = {
            id: memberInfo[i].id,
            num: this.calculationNum(i),
            name: memberInfo[i].name,
            username: memberInfo[i].username,
            password: memberInfo[i].password,
            roleType: memberInfo[i].roleType,
            status: memberInfo[i].memberStatus,
          };
          this.member[i] = memberGroup;
          this.allMember.push(this.member[i]);
        }
        this.tableLoading = false;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    apiRunApiFuncFetchInit() {
      ApiFunc.runApiFunc(this.fetchInit);
    },
    calculationNum(index) {
      const perPage = this.pagination.perPage;
      const page = this.pagination.page;
      let num = NaN;

      num = index + 1 + (perPage * page - perPage);
      return num;
    },
    logout() {
      this.deleteCookie('accessToken');
      this.deleteCookie('refreshToken');
      this.$router.push('/login');
    },
    deleteCookie(name) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    async getStatus() {
      try {
        this.status = [];
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/codes/member-status`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        let tempStatusArray = [];

        let memberStatus = res.data.data;
        for (let i = 0; i < memberStatus.length; i++) {
          let eachData = {
            text: memberStatus[i].name,
            value: memberStatus[i].code,
          };
          tempStatusArray.push(eachData);
        }
        this.status = tempStatusArray;

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getWaiting() {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/system-setting`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }

        if (res.data.data === null) {
          this.waitingPerTeam = 1;
        } else {
          this.systemSettingId = res.data.data.id;
          this.waitingPerTeam = res.data.data.expectedWaitTime;
        }

        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getRoleType() {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/members/me`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status !== 200) {
          return res;
        }
        this.currentName = res.data.data.name;
        let loginMemberRoleType = res.data.data.roleType;
        if (loginMemberRoleType === 'ROLE_MANAGER') {
          this.currentRoleType = '관리자';
        } else if (loginMemberRoleType === 'ROLE_WIZARD') {
          this.currentRoleType = '위자드';
        } else if (loginMemberRoleType === 'ROLE_SALES') {
          this.currentRoleType = '영업사원';
        }
        return res;
      } catch (e) {
        return e.response;
      }
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- 필터 관련 함수 ---------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    applyFilter() {
      this.pagination.page = 1;
      ApiFunc.runApiFunc(this.fetchInit);
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- 모달 관련 함수 ---------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    openModal() {
      this.createMember = true;
      this.rowId = null;
    },
    closeModal() {
      this.rowId = null;
      this.createMember = false;
      this.changeWaiting = false;
    },
    gotoDetail(id) {
      this.rowId = id;
      this.createMember = true;
    },
    updateMemberInfo() {
      this.fetchInit();
    },
    openWaitingModal() {
      this.changeWaiting = true;
    },
    updateWaiting() {
      this.getWaiting();
    },
    //-------------------------------------------------------------------------------------------------------------------------
    //------------------------------------- 테이블 데이터 변경 함수 -------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    apiRunApiFuncAssignStatus(rowId, status, name, roleType) {
      ApiFunc.runApiFuncWith4Params(
        this.assignStatus,
        rowId,
        status,
        name,
        roleType
      );
    },
    async assignStatus(rowId, status, name, roleType) {
      try {
        const token = VueCookies.get('accessToken');
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/members/${rowId}`,
          {
            memberStatus: status,
            name: name,
            roleType: roleType,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status !== 200) {
          return res;
        }

        return res;
      } catch (e) {
        return e.response;
      }
    },
    gotoManager() {
      if (this.allPageLoad === false) {
        return;
      }
      if (this.currentRoleType === '관리자') {
        this.$router.push('/manager');
      }
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'NotoSansKR_bold';
  src: url('../assets/font/NotoSansKR-Bold.otf');
}
@font-face {
  font-family: 'NotoSansKR_regular';
  src: url('../assets/font/NotoSansKR-Regular.otf');
}
@font-face {
  font-family: 'NotoSansKR_light';
  src: url('../assets/font/NotoSansKR-Light.otf');
}
.tablet {
  display: flex;
  flex-direction: column;
}
.topArea {
  height: auto;
  background-color: #fafafa;
  border-bottom: #bebebe solid 1px;
}
.topArea .logoImg {
  width: 150px;
}
.topArea .systemTitle {
  color: #3a3a3a;
  font-weight: bold;
  font-size: 28px;
  font-family: 'NotoSansKR_bold';
}
.topArea .navZone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.topArea .subTitle {
  font-size: 16px;
  font-family: 'NotoSansKR_bold';
  padding-right: 5px;
}
.topArea .profile {
  color: #1976d2;
  font-size: 16px;
  font-family: 'NotoSansKR_bold';
  padding-right: 5px;
  vertical-align: middle;
}
.topArea .logoutButton {
  width: 20px;
  height: auto;
}
.createButton {
  font-family: 'NotoSansKR_regular';
}
.v-data-table-header {
  color: #3a3a3a;
  font-size: 15px;
  text-align: center;
  background-color: #fafafa;
}
.v-data-table-header th {
  font-size: 14px !important;
  font-family: 'NotoSansKR_regular' !important;
  border-top: solid #1976d2;
}
.v-data-table td {
  color: #707070;
  font-family: 'NotoSansKR_light';
  text-align: center;
  border-bottom: #bebebe solid 1px;
}
.v-data-table tr:nth-last-child(odd) {
  background-color: #fafafa;
}
th {
  min-width: 100px !important;
}
</style>
