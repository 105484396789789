<!--
* 로그인 페이지
* 쿠키를 확인 후 메인에서 로그인 페이지로 이동
-->
<template>
  <v-app>
    <v-container fluid fill-height justify-center>
      <v-col class="areas" md="8" lg="5">
        <div class="titleArea">
          <img
            src="../assets/img/logo_volvo_black.png"
            alt="볼보 로고 이미지"
            class="volvoLogoImg"
          />
          <span class="systemTitle">고객 대기관리 시스템</span>
        </div>
        <div class="inputArea">
          <v-row justify="center">
            <v-col class="pb-0" md="12" lg="8">
              <v-text-field
                v-model="userId"
                placeholder="아이디를 입력하세요."
                outlined
                hide-details
                dense
                full-width
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="12" lg="8">
              <v-text-field
                v-model="userPw"
                placeholder="비밀번호를 입력하세요."
                outlined
                hide-details
                dense
                full-width
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keyup.enter="login"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="12" lg="8">
              <v-btn
                class="loginButton"
                color="#284e80"
                dark
                elevation="0"
                @click="login"
                >로그인</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="notice" justify="center">
            <v-col md="12" lg="8">
              <span>
                ※ 비밀번호를 분실하셨을 경우 관리자에게 문의하시길 바랍니다.
              </span>
              <!-- <br /> -->
              <!-- <span>※ 긴급 관리자 연락처: 010-1234-5678</span> -->
            </v-col>
          </v-row>
        </div>
        <div class="footerArea">
          <img
            src="../assets/img/logo_ajuautorium.png"
            alt="아주오토리움 로고 이미지"
            class="ajuLogoImg"
          />
        </div>
      </v-col>
    </v-container>
  </v-app>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
  data: () => ({
    userId: '',
    userPw: '',
    showPassword: false,
  }),
  mounted() {
    this.goToDashboardPage();
  },
  methods: {
    //-------------------------------------------------------------------------------------------------------------------------
    //-------------------------------- 로그인 및 리다이렉션 관련 함수 -----------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------
    login() {
      if (this.userId === '') {
        alert('아이디를 입력해주세요');
      } else if (this.userPw === '') {
        alert('비밀번호를 입력해주세요');
      } else {
        this.$axios
          .post(`${process.env.VUE_APP_API_URL}/api/auth/login`, {
            username: this.userId,
            password: this.userPw,
          })
          /**
           * axios에서 콜백함수에 arrow function이아닌 function()을 사용하면 this를사용할 때 뷰 인스턴스를 가르키는 것이 아니라 함수 내부를 가르키기 때문에 arrow function을 사용해야 한다
           */
          .then(function (res) {
            if (res.status === 200) {
              VueCookies.set('accessToken', res.data.data.accessToken, 0);
              VueCookies.set('refreshToken', res.data.data.refreshToken, 0);
            }
          })
          .catch(function (err) {
            alert('잘못된 로그인 정보입니다.');
          })
          .then(() => {
            this.goToDashboardPage();
            return;
          });
      }
    },
    goToDashboardPage() {
      const token = VueCookies.get('accessToken');
      if (token) {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style scoped>
#app {
  background-color: #fafafa;
}
.volvoLogoImg {
  width: 200px;
  height: auto;
}
.ajuLogoImg {
  width: 150px;
  height: auto;
}
.areas {
  display: flex;
  width: 200px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.areas > .titleArea {
  display: flex;
  height: 33%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
}
.areas > .titleArea > .systemTitle {
  color: '#3a3a3a';
  font-weight: bold;
  font-size: 28px;
  padding-top: 15px;
}
.areas > .inputArea {
  height: 57%;
  padding-top: 30px;
}
.loginButton {
  width: 100%;
  font-size: 15px;
}
.notice {
  color: #707070;
  font-size: 13px;
}
.areas > .footerArea {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
}
.areas > .footerArea > img {
  margin: 16px;
}
</style>
