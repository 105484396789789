import Vue from 'vue';
import App from './App.vue';

import router from './router';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import Axios from 'axios';
import JWTDecode from 'jwt-decode';
import VueCookies from 'vue-cookies';
import VueMoment from 'vue-moment';
import '@mdi/font/css/materialdesignicons.css';

/**
 * 애플리케이션 진입점으로 Vue 초기화, 폴더 , 전역, 컴포넌트 , 라이브러리 등록 수행
 * import App 을 사용해 최상위 App.vue 컴포넌트 지정
 * new Vue({}.mount() 를 사용해 index.html 파일 div id 값 설정 및 렌더링 시작점 지정
 * router를 import한 뒤 new Vue() 속성에 넣어 사용
 */

Vue.use(Vuetify);
Vue.use(VueCookies);
Vue.use(VueMoment);

Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$jwtDecode = JWTDecode;
new Vue({
  router,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi',
    },
  }),
  render: (h) => h(App),
}).$mount('#app');
